import { FC } from "react";
import LoginFooter from "../../components/element/LoginFooter";

const Tenant: FC = () => {

    const handleForgotPassword = () => {
        window.location.href = "forgotpassword";
    }

    const handleNewAccount = () => {
        window.location.href = "createnewaccount";
    }

    return (
        <div className="w-full flex flex-col justify-center items-center py-12 bg-[#f2f7fa] relative">
            <div className="lg:w-96 md:w-96 sm:w-full w-full flex flex-col justify-start items-center rounded-lg shadow-lg py-4 bg-white">
                <div className="w-full flex flex-col justify-center items-center mt-12">
                    <a href="/">
                        <img src="/image/loginLogo.jpg" alt="" />
                    </a>
                    <span className="text-2xl">Online Portal</span>
                </div>
                <div className="w-full flex flex-col justify-center items-center px-4 mt-8">
                    <div className="w-full flex flex-col gap-2">
                        <label className="font-semibold text-md text-[#637989] ">Email</label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" />
                    </div>
                    <div className="w-full flex flex-col gap-2 relative">
                        <label className="font-semibold text-md text-[#637989] ">Password</label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" type="password" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"
                            className="bi bi-eye absolute top-[50px] right-3 -translate-y-1/2 cursor-pointer z-20 opacity-100"
                            viewBox="0 0 16 16">
                            <path
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z">
                            </path>
                            <path
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z">
                            </path>
                        </svg>
                    </div>
                    <button className="w-full bg-[#0063a5] text-white py-2 rounded">Sign In</button>
                    <a href="#" className="w-full flex justify-center items-center text-[#0063a5] mt-4" onClick={() => handleForgotPassword()}>Forgot your password?</a>
                    <a href="#" className="w-full flex justify-center items-center text-[#0063a5] mt-4" onClick={() => handleNewAccount()}>Need to set up an account?</a>
                </div>
            </div>
            <a href="" className="text-[#0063a5] mt-8 hover:border-b border-b-[#0063a5]">Prefieres Español?</a>
            <div className="flex justify-center items-center gap-4 mt-6">
                <img className="cursor-pointer" src="/image/AppStore.svg" alt="" />
                <img className="cursor-pointer" src="/image/GooglePlay.svg" alt="" />
            </div>
            <LoginFooter />
        </div>
    )
}

export default Tenant;