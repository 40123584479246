import { FC } from "react";
import OwnerFooter from "../../components/element/OwnerFooter";

const OwnerAccountCreate: FC = () => {
    return (
        <div className="w-full flex flex-col justify-center items-center py-12 bg-[#f2f7fa] relative">
            <div className="lg:w-96 md:w-96 sm:w-full w-full flex flex-col justify-start items-center rounded-lg shadow-lg py-4 bg-white">
                <div className="w-full flex flex-col justify-center items-center mt-4 px-4">
                    <a href="/">
                        <img src="/image/loginLogo.jpg" alt="" />
                    </a>
                    <span className="text-lg">Sign up for the Owner Portal</span>
                    <span className="text-md mt-4">We've updated our login process. For security purposes, enter your email address below so we can send you an account activation email.</span>
                    <span className="text-md mt-4">Check your email and follow the instructions. You will be asked to create a password.</span>
                </div>
                <div className="w-full flex flex-col justify-center items-center px-4 mt-8">
                    <div className="w-full flex flex-col gap-2">
                        <label className="font-semibold text-md text-[#637989] flex justify-start gap-2">Email</label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" />
                    </div>
                    <button className="w-full bg-[#0063a5] text-white py-2 rounded">Sign up</button>
                </div>
            </div>
            <OwnerFooter />
        </div>
    )
}

export default OwnerAccountCreate;