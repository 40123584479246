import { FC } from "react";
import { Technology } from "../data/Data";
import InputElement from "../components/element/InputElement";

const OurTechnology: FC = () => {
    return (
        <section className="h-full relative transition-colors duration-500 mt-16">
            <div className="w-full mx-auto flex flex-col justify-center items-center">
                <div className="w-full h-60 flex items-center justify-center relative z-10">
                    <img className="w-full h-full object-cover absolute" src="/image/bg-image.jpg" alt="" />
                    <div className="w-full h-full bg-[rgba(0,0,0,0.3)] absolute"></div>
                    <span className="text-white lg:text-6xl md:text-5xl sm:text-4xl text-3xl z-10 font-medium uppercase leading-normal">Our Technology</span>
                </div>
                <div className="w-full max-w-5xl py-16 mb-6 flex lg:flex-row md:flex-row sm:flex-col flex-col justify-start items-start">
                    <div className="lg:w-[60%] md:w-[60%] sm:w-full w-full px-4">
                        <div className="w-full">
                            <h1 className="uppercase text-2xl py-6 font-thin">Our Technology Advantage</h1>
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Our goal is to provide you with the best service and manage your properties efficiently
                                and effectively. Our team has invested in a complete and modern software solution to help us be more
                                efficient and make you more money. We are excited to share several of the new capabilities and how they
                                will benefit you.</span>
                        </div>
                        <div className="w-full pl-6 mt-4">
                            <ul className="list-disc pl-5 space-y-4">
                                {
                                    Technology.map((item, index) => (
                                        <li key={index} style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>
                                            <strong>{item.title}</strong>
                                            {item.content}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className={`lg:w-[40%] md:w-[40%] sm:w-full px-4 w-full space-y-2`}>
                        <div className="w-full">
                            <h1 className="uppercase text-2xl py-6 font-thin">Have Question?</h1>
                        </div>
                        <div className="w-full">
                            <h1 className="uppercase text-2xl py-3 font-thin">Call Us:</h1>
                            <span className="text-xl font-thin text-[#a1a1a1]">(786) 506-9416</span>
                        </div>
                        <h1 className="uppercase text-2xl py-3 font-thin">Email Us:</h1>
                        <InputElement />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurTechnology;