import { FC } from "react";

const Footer:FC = () => {

    const handlePageDirectory = (router : string) => {
        if (router === "home") window.location.href = "/";
        else if (router === "Vacancies") window.location.href = "/vacancies";
        else if (router === "Tenants") window.location.href = "/tenant";
        else if (router === "Owners") window.location.href = "/owner";
        else window.location.href = "/contactus"
    }

    return(
        <div className="w-full h-72 flex justify-center items-center border-t-2 border-t-gray-200">
            <div className="w-full h-full max-w-5xl flex flex-col justify-center items-center space-y-4">
                <div className="w-full flex lg:flex-row md:flex-row sm:flex-row flex-col justify-between items-center px-12 gap-4">
                    <span className="uppercase text-lg text-gray-400 font-medium cursor-pointer" onClick={() => handlePageDirectory("home")}>home</span>
                    <span className="uppercase text-lg text-gray-400 font-medium cursor-pointer" onClick={() => handlePageDirectory("Vacancies")}>Vacancies</span>
                    <span className="uppercase text-lg text-gray-400 font-medium cursor-pointer" onClick={() => handlePageDirectory("Tenants")}>Tenants</span>
                    <span className="uppercase text-lg text-gray-400 font-medium cursor-pointer" onClick={() => handlePageDirectory("Owners")}>Owners</span>
                    <span className="uppercase text-lg text-gray-400 font-medium cursor-pointer" onClick={() => handlePageDirectory("Contact")}>Contact</span>
                </div>
                <div className="w-full flex justify-center items-center">
                    <span className="text-[#4d4d4d]">Property Managers, LLC © 2018</span>
                </div>
            </div>
        </div>
    )
}

export default Footer;