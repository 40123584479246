import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

const VacanciesSection: FC = () => {
    return (
        <div className="w-full flex justify-center items-start bg-white p-3 mt-4 gap-8 lg:flex-row md:flex-row sm:flex-row flex-col">
            <div className="lg:w-[30%] md:w-[40%] sm:w-[35%] w-full flex justify-center items-start">
                <img src="/image/vacancies-section-image.jpg" alt="" />
            </div>
            <div className="lg:w-[70%] md:w-[60%] sm:w-[65%] w-full flex flex-col justify-start items-center">
                <div className="w-full flex justify-start items-center">
                    <div className="flex flex-col justify-center items-start lg:pr-6 md:pr-6 sm:pr-3 pr-2 border-r border-gray-200">
                        <p className="uppercase text-sm text-gray-500">rent</p>
                        <span className="lg:text-2xl md:text-2xl sm:text-xl text-lg">$1,650</span>
                    </div>
                    <div className="flex flex-col justify-center items-start lg:px-6 md:px-6 sm:px-3 px-3 border-r border-gray-200">
                        <p className="uppercase text-sm text-gray-500">Bed / Bath</p>
                        <span className="lg:text-2xl md:text-2xl sm:text-xl text-lg">1 bd / 1 ba</span>
                    </div>
                    <div className="flex flex-col justify-center items-start lg:px-6 md:px-6 sm:px-3 px-3 border-r border-gray-200">
                        <p className="uppercase text-sm text-gray-500">Available</p>
                        <span className="lg:text-2xl md:text-2xl sm:text-xl text-lg">NOW</span>
                    </div>
                </div>
                <div className="w-full flex flex-col justify-center items-start mt-4">
                    <a href="" className="text-2xl hover:border-b-2 border-b-black">Large 1 bedroom available! - Quick Move In</a>
                    <span className="flex text-lg text-[#2d2f30] mt-2">160 NW 11th Terrace, #2, Miami, FL 33136
                        <span className="ml-4 flex justify-center items-center gap-1"><FontAwesomeIcon icon={faLocationDot} color="#fa5342" />Map</span>
                    </span>
                    <span className="text-[#505051] mt-3 text-lg">This cozy 1 bedroom home is perfectly tucked away on a 1 way street, with its short walk to downtown Miami, and public transit stops, you will love calling this home!</span>
                    <span className="text-[#505051] mt-3 text-lg">With spacious rooms, and large closets and professional local management, you will find the move to your new home easy!</span>
                    <span className="text-[#505051] mt-4 text-lg">Please call (786) 506-9416 to schedule a showing</span>
                    <span className="text-[#505051] mt-5 text-lg">Call us today, or visit <a href="https://www.nadlanmanagement.com/" className="text-blue-600 hover:border-b border-b-blue-600">www.NadlanManagement.com</a> to ...</span>
                    <span className="text-[#272727] mt-6 text-lg"><strong>Utilities Included:</strong> Household Trash, Lawn Care, Pest Control</span>
                    <span className="text-[#272727] mt-1 text-lg"><strong>Appliances: </strong> A/C- Wall Unit, Oven/Stove, Refrigerator</span>
                    <span className="text-[#272727] mt-1 text-lg"><strong>Pet Policy:</strong> Cats allowed, Dogs allowed</span>
                </div>
                <div className="w-full flex justify-end items-center mt-6">
                    <div className="flex justify-center items-center gap-2">
                        <button className="rounded px-3 py-1 border border-gray-500">View Details</button>
                        <button className="rounded px-3 py-1 bg-gray-400 border border-gray-400 text-gray-100">Apply Now</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VacanciesSection;