import { FC } from "react";
import { PortalServices } from "../data/Data";

const HomePage: FC = () => {

    const handlePageDirectory = (router: number) => {
        if (router === 0) window.location.href = "/tenantportal"
        else if (router === 1) window.location.href = "/tenant"
        else window.location.href = "/maintenance"
    }

    return (
        <section id="homepage" className="h-auto relative mt-16 transition-colors flex justify-center items-center flex-col">
            <div className="w-full h-[32rem] relative flex flex-col justify-center items-center">
                <img src="/image/bg-image.jpg" className="w-full h-full absolute object-cover" alt="" />
                <div className="w-full h-full bg-[rgba(0,0,0,0.3)] absolute"></div>
                <div className="w-full max-w-5xl flex justify-center flex-col items-center gap-4">
                    <h1 className="uppercase lg:text-6xl md:text-5xl sm:text-3xl text-3xl font-medium z-10 text-white tracking-widest">Welcome home</h1>
                    <span className="uppercase lg:text-lg md:text-lg sm:text-md text-sm font-medium z-10 text-white tracking-widest text-center">Whether you want to find a new place to live or need a rental filled, when you’re here, you're home.</span>
                    <button className="uppercase px-8 py-3 text-gray-300 border-gray-200 border-2 rounded z-10 mt-12 hover:bg-gray-100 hover:text-gray-500 transition duration-300">See available units</button>
                </div>
            </div>
            <div className="w-full max-w-5xl h-auto flex flex-col justify-center items-center py-16 gap-8">
                <h1 className="lg:text-4xl md:text-4xl sm:text-3xl text-2xl font-thin text-[#2b2b2b] uppercase px-4">Tenant Portal Services</h1>
                <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 text-white text-sm text-center font-bold leading-6">
                    {
                        PortalServices.map((item, index) => (
                            <div key={index} className="p-4 rounded-lg bg-white space-y-4 flex flex-col justify-start items-center">
                                <img src={item.image} alt="" />
                                <h1 className="uppercase lg:text-2xl md:text-lg font-thin text-[#2b2b2b]">{item.title}</h1>
                                <div className="lg:h-16 md:h-24 flex justify-start items-start">
                                    <span className="text-lg font-medium text-start text-[#6b6767]" style={{ "fontFamily": "Droid Serif" }}>{item.content}</span>
                                </div>
                                <div className="pt-8">
                                    <button className="py-3 lg:px-8 px-6 bg-white border-gray-400 border-2 rounded-md text-gray-400 text-xs hover:bg-gray-400 uppercase hover:text-white transition duration-300" onClick={() => handlePageDirectory(index)}>Learn more</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default HomePage;