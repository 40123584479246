import { FC } from "react";

const UsefulLinks: FC = () => {
    return (
        <section className="h-full relative transition-colors duration-500 mt-16">
            <div className="w-full mx-auto flex flex-col justify-center items-center">
                <div className="w-full h-60 flex items-center justify-center relative z-10">
                    <img className="w-full h-full object-cover absolute" src="/image/bg-image.jpg" alt="" />
                    <div className="w-full h-full bg-[rgba(0,0,0,0.3)] absolute"></div>
                    <span className="text-white lg:text-6xl md:text-5xl sm:text-4xl text-3xl z-10 font-medium uppercase tracking-widest">useful links</span>
                </div>
                <div className="w-full max-w-5xl py-16 mb-6 flex flex-col justify-start items-start px-2">
                    <div className="w-full flex flex-col justify-center items-center">
                        <h1 className="text-2xl font-medium">BELOW IS THE INFORMATION YOU WILL NEED FOR A SMOOTH MOVE-IN.</h1>
                    </div>
                    <div className="w-full flex flex-col justify-start items-start">
                        <h1 className="uppercase text-2xl py-6 font-thin">for city of miami residents</h1>
                        <div className="w-full">
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>​The following are the utility companies you need to call to set up service:</span>
                            <div className="w-full pl-6 space-y-1 mt-2">
                                <ul className="list-disc pl-5 space-y-1">
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>Florida Power and Light (FPL) 305-442-8770</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>AT&T 888-757-6500</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>Xfinity-Comcast 800-266-2278</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>Miami Dade Water and Sewer 305-665-7477</li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full flex flex-col justify-center items-center mt-4">
                            <span className="font-bold text-center text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>​*You must be home for service to be hooked-up. *Satellite Dishes and other equipment are not permitted to be attached to the building without prior written authorization from management.</span>
                            <span className="text-center text-lg mt-4" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>For information regarding Miami-Dade Public Schools, visit Miami-Dade Public Schools</span>
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-start items-start">
                        <h1 className="uppercase text-2xl py-6 font-thin">for city of miami beach residents</h1>
                        <div className="w-full">
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>​The following are the utility companies you need to call to set up service:</span>
                            <div className="w-full pl-6 space-y-1 mt-2">
                                <ul className="list-disc pl-5 space-y-1">
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>Florida Power and Light (FPL) 305-442-8770</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>AT&T 888-757-6500</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>*TECO People’s Gas 877-832-6747</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>*Atlantic Broadband (Cable/DSL) 305-861-156</li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full flex flex-col justify-center items-center mt-4">
                            <span className="font-bold text-center text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>​*You must be home for service to be hooked-up. *Satellite Dishes and other equipment are not permitted to be attached to the building without prior written authorization from management.</span>
                            <span className="text-center text-lg mt-4" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>City of Miami Beach Parking Department</span>
                            <span className="text-center text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Bring your driver’s license, proof of registration, and proof of residence to:</span>
                            <span className="text-center text-lg text-gray-400" style={{ "fontFamily": "Droid Serif", "fontStyle": "italic" }}>1755 Meridian Ave.  Suite 100.  Miami Beach, FL 33139</span>
                            <span className="text-center text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>(305) 673-7505</span>
                        </div>
                        <div className="w-full flex flex-col justify-center items-center mt-10">
                            <span className="font-bold text-center text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>In order to park on the street in Miami Beach, you must purchase a Zone Permit parking decal. Below is the information you need in order to obtain one:</span>
                            <span className="text-center text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Bring your driver’s license, proof of registration, and proof of residence to</span>
                            <span className="text-center text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Please call the parking department for pricing on the decals and guest passes.</span>
                            <span className="text-center text-lg mt-4 text-gray-400" style={{ "fontFamily": "Droid Serif" }}>For information regarding Miami-Dade Public Schools, visit Miami-Dade Public Schools</span>
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-start items-start">
                        <h1 className="uppercase text-2xl py-6 font-thin">for city of opa locka residents</h1>
                        <div className="w-full">
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>​The following are the utility companies you need to call to set up service:</span>
                            <div className="w-full pl-6 space-y-1 mt-2">
                                <ul className="list-disc pl-5 space-y-1">
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>Florida Power and Light (FPL) 305-442-8770</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>AT&T 888-757-6500</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>Xfinity-Comcast 800-266-2278</li>
                                    <li className="cursor-pointer" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)", "fontStyle": "italic" }}>City of Opa Locka - (305) 953-2868</li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full flex flex-col justify-center items-center mt-4">
                            <span className="font-bold text-center text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>​*You must be home for service to be hooked-up. *Satellite Dishes and other equipment are not permitted to be attached to the building without prior written authorization from management.</span>
                            <span className="text-center text-lg mt-4" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>For information regarding Miami-Dade Public Schools, visit Miami-Dade Public Schools</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UsefulLinks;