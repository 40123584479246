import { FC, useState } from "react";
import { category, childrenCategory } from "../data/Data";

const Header: FC = () => {

    const [isOpenHeader, setIsOpenHeader] = useState<boolean>(false);
    const [currentParentID, setCurrentParentID] = useState<number>(0);
    const [isActive, setIsActive] = useState<boolean>(false);

    const handleSetCurrentParentID = (currentID: number) => {
        if (currentID === currentParentID) {
            setIsActive(!isActive);
        }
        setCurrentParentID(currentID);
    }

    const pageDirectory = (index: number, category: string) => {
        if (index === 1) {
            if (category === "Home") window.location.href = "/";
            else if (category === "Vacancies") window.location.href = "vacancies";
            else if (category === "Contact Us") window.location.href = "contactus";
        } else {
            if (category === "Tenant Portal") window.location.href = "tenantportal";
            else if (category === "Maintenance Requests") window.location.href = "maintenance";
            else if (category === "Useful Links") window.location.href = "usefullinks";
            else if (category === "Management Services") window.location.href = "managementservices";
            else if (category === "Our Technology") window.location.href = "ourtechnology";
            else if (category === "Tenant") window.location.href = "tenant";
            else if (category === "Owner") window.location.href = "owner";
        }
    }

    return (
        <nav
            className="fixed py-5 top-0 z-40 w-full bg-white transition-colors duration-500 lg:z-50">
            <div className="container mx-auto">
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="flex justify-between lg:flex-row">
                        <a href="/"
                            className="flex">
                            <img src="/image/Logo.png" className="object-cover" alt="" />
                        </a>
                        <button data-collapse-toggle="navbar-nav-example" type="button" onClick={() => setIsOpenHeader(!isOpenHeader)}
                            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none"
                            aria-controls="navbar-nav-example" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                    <div className="hidden w-full lg:flex lg:pl-11">
                        <ul className="flex items-center flex-col mt-4 lg:mt-0 lg:ml-auto lg:flex-row gap-4 space-x-8">
                            {
                                category.map((item, index) => (
                                    <li key={index}>
                                        <span className="group relative w-auto group">
                                            <a className="flex items-center justify-between text-sm lg:text-xs font-medium transition-all duration-500 uppercase cursor-pointer text-gray-500" onClick={() => pageDirectory(1, item.category)}>{item.category}</a>
                                            <div className="dropdown-menu rounded-xl shadow-lg bg-white absolute top-2 py-2 right-0 w-auto mt-2 hidden group-hover:block animate__animated animate__fadeIn" aria-labelledby="dropdown-hover">
                                                {
                                                    childrenCategory.filter((item => item.parentID === index)).map((ChildrenCategory, ChildrenIndex) => (
                                                        <span key={ChildrenIndex}>
                                                            <a className="block px-6 py-2 text-nowrap hover:bg-gray-100 text-gray-900 font-thin text-sm uppercase" onClick={() => pageDirectory(2, ChildrenCategory.title)} href="#">{ChildrenCategory.title}</a>
                                                        </span>
                                                    ))
                                                }
                                            </div>
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    {
                        isOpenHeader && (
                            <div className="w-full h-screen flex flex-col items-center py-8 gap-2">
                                {
                                    category.map((Category, index) => (
                                        <div key={index} className="flex w-full flex-col items-start px-12 text-md font-medium transition-all duration-500 uppercase cursor-pointer text-gray-500 relative" onClick={() => pageDirectory(1, Category.category)}>
                                            {Category.category}
                                            {
                                                childrenCategory.filter((item => item.parentID === index)).map((ChildrenCategory, ChildrenIndex) => (
                                                    <>
                                                        <div key={ChildrenIndex} className="px-4" onClick={() => pageDirectory(2, ChildrenCategory.title)}>{currentParentID === index && isActive ? ChildrenCategory.title : ""}</div>
                                                        <div className="absolute right-10 text-lg" onClick={() => handleSetCurrentParentID(index)}>
                                                            {
                                                                isActive && currentParentID === index ? "-" : "+"
                                                            }
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </nav>
    )
}

export default Header;