import { FC } from "react";
import { PriceRange } from "../../data/Data";

const Filter: FC = () => {
    return (
        <div className="w-full flex justify-center items-center border">
            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-4 text-sm text-center font-bold leading-6">
                <div className="flex flex-col justify-start items-start">
                    <h1 className="text-2xl font-bold">Price Range</h1>
                    <div className="flex justify-center items-center py-2">
                        <select name="" id="" className="w-40 py-1 outline-none">
                            {
                                PriceRange.map((item, index) => (
                                    index === 0 ? <option key={index} value="No Min">No Min</option> : <option key={index} value={item.content}>{item.content}</option>
                                ))
                            }
                        </select>
                        <p className="px-4 font-bold">to</p>
                        <select name="" id="" className="w-40 py-1 outline-none">
                            {
                                PriceRange.map((item, index) => (
                                    index === 0 ? <option key={index} value="No Max">No Max</option> : <option key={index} value={item.content}>{item.content}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="flex justify-between items-start">
                    <div className="flex flex-col justify-start items-start">
                        <h1 className="text-2xl font-bold">Bedrooms</h1>
                        <div className="flex justify-center items-center py-2">
                            <select name="" id="" className="w-40 py-1 outline-none">
                                <option value="0">0+</option>
                                <option value="1">1+</option>
                                <option value="2">2+</option>
                                <option value="3">3+</option>
                                <option value="4">4+</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-start">
                        <h1 className="text-2xl font-bold">Bathrooms</h1>
                        <div className="flex justify-center items-center py-2">
                            <select name="" id="" className="w-40 py-1 outline-none">
                                <option value="0">0+</option>
                                <option value="1">1+</option>
                                <option value="2">2+</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-start items-start">
                    <h1 className="text-2xl font-bold">Cities</h1>
                    <div className="w-full flex justify-center items-center py-2">
                        <select multiple name="" id="" className="w-full h-28 px-2 py-1 outline-none">
                            <option value="All Cities">All Cities</option>
                            <option value="Miami">Miami</option>
                            <option value="Miami Beach">Miami Beach</option>
                            <option value="North Miami">North Miami</option>
                            <option value="North Miami Beach">North Miami Beach</option>
                            <option value="Opa Locka">Opa Locka</option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-col justify-start items-start">
                    <h1 className="text-2xl font-bold">Zip Codes</h1>
                    <div className="w-full flex justify-center items-center py-2">
                        <select multiple name="" id="" className="w-full h-28 px-2 py-1 outline-none">
                            <option value="33054">33054</option>
                            <option value="33136">33136</option>
                            <option value="33138">33138</option>
                            <option value="33139">33139</option>
                            <option value="33140">33140</option>
                            <option value="33141">33141</option>
                            <option value="33142">33142</option>
                            <option value="33147">33147</option>
                            <option value="33161">33161</option>
                            <option value="33162">33162</option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-col justify-start items-start">
                    <h1 className="text-2xl font-bold">Cats Allowed</h1>
                    <div className="w-full flex justify-center items-center py-2">
                        <select name="" id="" className="w-full py-1 outline-none">
                            <option value=""></option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-col justify-start items-start">
                    <h1 className="text-2xl font-bold">Dogs Allowed</h1>
                    <div className="w-full flex justify-center items-center py-2">
                        <select name="" id="" className="w-full py-1 outline-none">
                            <option value=""></option>
                            <option value="Large">Large</option>
                            <option value="Small">Small</option>
                            <option value="None">None</option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-col justify-start items-start">
                    <h1 className="text-2xl font-bold mb-2">Dogs Allowed</h1>
                    <div className="relative max-w-sm">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </div>
                        <input type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm block w-full ps-10 p-1 outline-none" placeholder="Select date"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter;