import { FC } from "react";

const Map: FC = () => {
    return (
        <div className="relative w-full h-auto overflow-hidden bg-cover bg-[50%] bg-no-repeat ">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11672.945750644447!2d-122.42107853750231!3d37.7730507907087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858070cc2fbd55%3A0xa71491d736f62d5c!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1619524992238!5m2!1sen!2sus"
                width="100%" height="480" style={{ "border": 0 }} loading="lazy"></iframe>
        </div>
    )
}

export default Map;