import { FC, useState } from "react";
import VacanciesHeader from "../components/element/VacanciesHeader";
import VacanciesSection from "../components/element/VacanciesSection";
import Map from "../components/element/Map";
import Filter from "../components/element/Filter";

const Vacancies: FC = () => {

    const [isMap, setIsMap] = useState<boolean>(false);
    const [isFilter, setIsFilter] = useState<boolean>(false);

    return (
        <section className="h-full relative transition-colors duration-500 mt-16">
            <div className="w-full mx-auto flex flex-col justify-center items-center">
                <div className="w-full h-60 flex items-center justify-center relative z-10">
                    <img className="w-full h-full object-cover absolute" src="/image/bg-image.jpg" alt="" />
                    <div className="w-full h-full bg-[rgba(0,0,0,0.3)] absolute"></div>
                    <span className="text-white lg:text-6xl md:text-5xl sm:text-4xl text-3xl z-10 font-medium uppercase tracking-widest">Vacancies</span>
                </div>
                <div className="w-full h-[68rem] max-w-5xl flex justify-start items-center flex-col overflow-y-auto mt-16">
                    <VacanciesHeader setIsShowMap={setIsMap} isShowMap={isMap} setIsShowFilter={setIsFilter} isShowFilter={isFilter} />
                    <div className="w-full flex flex-col justify-start items-center bg-[#e5e5e5] py-4 px-2">
                        {
                            isMap && <Map />
                        }
                        {
                            isFilter && <Filter />
                        }
                        <div className="w-full flex flex-col justify-start items-center gap-1">
                            <h1 className="text-3xl">Current Listings</h1>
                            <p className="text-md">Showing all available listings</p>
                        </div>
                        <VacanciesSection />
                        <VacanciesSection />
                        <VacanciesSection />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Vacancies;