import { FC } from "react";
import InputElement from "../components/element/InputElement";

const TenantPortal: FC = () => {
    return (
        <section className="h-full relative transition-colors duration-500 mt-16">
            <div className="w-full mx-auto flex flex-col justify-center items-center">
                <div className="w-full h-60 flex items-center justify-center relative z-10">
                    <img className="w-full h-full object-cover absolute" src="/image/bg-image.jpg" alt="" />
                    <div className="w-full h-full bg-[rgba(0,0,0,0.3)] absolute"></div>
                    <span className="text-white lg:text-6xl md:text-5xl sm:text-4xl text-3xl z-10 font-medium uppercase tracking-widest">Tenant Portal</span>
                </div>
                <div className="w-full max-w-5xl py-16 mb-6 flex lg:flex-row md:flex-row sm:flex-col flex-col justify-start items-start">
                    <div className="lg:w-[50%] md:w-[50%] sm:w-full w-full px-4">
                        <div className="w-full">
                            <div className="w-full">
                                <h1 className="uppercase text-2xl">Already have an account?</h1>
                                <h1 className="uppercase text-2xl py-2 font-thin">Pay your rent online.</h1>
                            </div>
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>It’s fast, easy, and secure, so why wait? Below, you’ll find some information on how to get started and a few reasons why so many others have already made the switch!</span>
                            <div className="py-6 lg:pl-10 md:pl-10 sm:pl-0 pl-0 w-full justify-center items-center">
                                <button className="py-2 lg:px-6 px-2 bg-white border-gray-400 border-2 rounded-md text-gray-400 text-xs hover:bg-gray-400 uppercase hover:text-white transition duration-300">Login to pay rent</button>
                            </div>
                        </div>
                        <div className="w-full">
                            <h1 className="uppercase text-2xl py-2 font-thin">Benefits</h1>
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Once you've signed up you can:</span>
                            <div className="w-full pl-6 space-y-1 mt-2">
                                <ul className="list-disc pl-5">
                                    <li style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>View and pay your bills anytime (24/7)</li>
                                    <li style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>View and pay your bills from anywhere (any computer with an internet connection)</li>
                                    <li style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Set up an automatic payment</li>
                                    <li style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Sign up for automatic reminder emails</li>
                                    <li style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Review your payment history</li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full mt-4 flex flex-col justify-start items-start gap-4">
                            <h1 className="uppercase text-2xl py-2 font-thin">Security</h1>
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>We understand if you have some reservations about entering your bank account information online. But fear not! Your information is password protected and all transactions are both encrypted and securely transmitted.</span>
                        </div>
                    </div>
                    <div className={`lg:w-[50%] md:w-[50%] sm:w-full px-4 w-full`}>
                        <div className="w-full">
                            <h1 className="uppercase text-2xl">Don't have an account?</h1>
                            <h1 className="uppercase text-2xl py-2 font-thin">We'll help you get set up.</h1>
                        </div>
                        <div className="w-full mb-4">
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Just let us know you want to pay your rent online by completing the form below and we’ll email you an account activation link.</span>
                        </div>
                        <InputElement />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TenantPortal;