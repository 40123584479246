import { FC } from "react";
import OwnerFooter from "../../components/element/OwnerFooter";

const ForgotPassword: FC = () => {

    const handleForgotPassword = () => {
    }

    return (
        <div className="w-full flex flex-col justify-center items-center py-12 bg-[#f2f7fa] relative">
            <div className="lg:w-96 md:w-96 sm:w-full w-full flex flex-col justify-start items-center rounded-lg shadow-lg py-4 bg-white">
                <div className="w-full flex flex-col justify-center items-center mt-12">
                    <a href="/">
                        <img src="/image/loginLogo.jpg" alt="" />
                    </a>
                </div>
                <div className="w-full flex flex-col justify-start items-start px-4 mt-8">
                    <span className="text-2xl">Forgot Your Password?</span>
                    <span className="text-md mt-4">Enter the email address associated with your Owner Portal account. We'll email you a link where you can easily create a new password.</span>
                    <div className="w-full flex flex-col gap-2 mt-4">
                        <label className="font-semibold text-md text-[#637989] ">Email</label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" />
                    </div>
                    <button className="bg-[#0063a5] text-white py-2 rounded px-4" onClick={() => handleForgotPassword()}>Send password reset email</button>
                </div>
            </div>
            <OwnerFooter />
        </div>
    )
}

export default ForgotPassword;