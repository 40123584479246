import { FC } from "react";
import InputElement from "../components/element/InputElement";

const ManagementServices: FC = () => {
    return (
        <section className="h-full relative transition-colors duration-500 mt-16">
            <div className="w-full mx-auto flex flex-col justify-center items-center">
                <div className="w-full h-60 flex items-center justify-center relative z-10">
                    <img className="w-full h-full object-cover absolute" src="/image/bg-image.jpg" alt="" />
                    <div className="w-full h-full bg-[rgba(0,0,0,0.3)] absolute"></div>
                    <span className="text-white lg:text-6xl md:text-5xl sm:text-4xl text-3xl z-10 font-medium uppercase leading-normal">Management Services</span>
                </div>
                <div className="w-full max-w-5xl flex flex-col justify-center items-center mt-10 px-2">
                    <div className="w-full flex flex-col justify-center items-center gap-2">
                        <h1 className="uppercase text-2xl font-thin">Are you looking for an efficient, transparent, and professional</h1>
                        <h1 className="uppercase text-2xl font-thin">​property management team, at a price that makes sense?</h1>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 text-white text-sm text-center font-bold leading-6 mt-6 lg:w-[85%] md:w-[85%] sm:w-full">
                        <ul className="list-disc pl-5 text-start">
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Local Expertise</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Maintenance Calls</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Experienced Staff</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Controlled Expenses</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Value Focused​</li>
                        </ul>
                        <ul className="list-disc pl-5 text-start">
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Vacancies Filled Fast</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Max Rental Income</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>High Quality Tenants</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Outstanding Service</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Low Tenant Turnover​</li>
                        </ul>
                        <ul className="list-disc pl-5 text-start">
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Efficient Collections</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Proper Accounting</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Reliable Distributions</li>
                            <li className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Customized Strategy​</li>
                        </ul>
                    </div>
                    <div className="w-full flex flex-col justify-center items-center gap-4 mt-12">
                        <h1 className="uppercase text-2xl font-thin">Sleep better at night knowing your property is run right.</h1>
                        <div className="w-full flex flex-col justify-center items-center">
                            <span className="text-xl font-medium" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>We specialize in managing multifamily properties including:</span>
                            <span className="text-xl font-medium" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>apartments, home communities, and more.</span>
                        </div>
                        <div className="w-full flex flex-col justify-center items-center">
                            <span className="text-xl font-medium" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>The principals of Nadlan Management have many years of experience</span>
                            <span className="text-xl font-medium" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>working with hundreds of properties and units.</span>
                            <span className="text-xl font-medium" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Let our experience benefit you.</span>
                        </div>
                    </div>
                </div>
                <div className="w-full max-w-5xl py-16 mb-6 flex flex-col justify-start items-start">
                    <div className="w-full justify-center items-center text-center">
                        <h1 className="uppercase text-2xl py-6 font-thin">Contact us for a free consultation.</h1>
                    </div>
                    <div className="w-full flex lg:flex-row md:flex-row sm:flex-col flex-col justify-start items-start">
                        <div className={`lg:w-[50%] md:w-[50%] sm:w-full px-4 w-full space-y-2`}>
                            <h1 className="uppercase text-2xl py-3 font-thin">Email Us:</h1>
                            <InputElement />
                        </div>
                        <div className="lg:w-[50%] md:w-[50%] sm:w-full w-full px-4">
                            <div className="w-full pl-6 mt-4">
                                <h1 className="uppercase text-2xl py-3 font-thin">Call us.</h1>
                                <p className="text-lg py-3 font-thin text-gray-400">(786) 506-9416</p>
                            </div>
                            <div className="w-full pl-6 mt-4">
                                <h1 className="uppercase text-2xl py-3 font-thin">Visit us.</h1>
                                <p className="text-lg font-thin" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>By Appointment Only</p>
                                <p className="text-lg font-thin" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>1545 northwest 8th ave suite 115</p>
                                <p className="text-lg font-thin" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>Miami , FL 33136</p>
                            </div>
                            <div className="relative w-full h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat mt-12 lg:ml-6">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11672.945750644447!2d-122.42107853750231!3d37.7730507907087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858070cc2fbd55%3A0xa71491d736f62d5c!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1619524992238!5m2!1sen!2sus"
                                    width="100%" height="480" style={{ "border": 0 }} loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ManagementServices;