import { FC } from "react";
import LoginFooter from "../../components/element/LoginFooter";

const CreatNewAccount: FC = () => {

    const handleLogin = () => {
        window.location.href = "tenant";
    }

    return (
        <div className="w-full flex flex-col justify-center items-center py-12 bg-[#f2f7fa] relative">
            <div className="lg:w-96 md:w-96 sm:w-full w-full flex flex-col justify-start items-center rounded-lg shadow-lg py-4 bg-white">
                <div className="w-full flex flex-col justify-center items-center mt-4 px-4">
                    <a href="/">
                        <img src="/image/loginLogo.jpg" alt="" />
                    </a>
                    <span className="text-lg">Sign Up For an Account</span>
                    <span className="text-md mt-4">Fill out your information below and we'll send you an activation link via email. To best match your account, please use the same information provided to your Property Manager.</span>
                </div>
                <div className="w-full flex flex-col justify-center items-center px-4 mt-8">
                    <div className="w-full flex flex-col gap-2">
                        <label className="font-semibold text-md text-[#637989] flex justify-start gap-2">First Name <p className="text-red-500">*</p> </label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <label className="font-semibold text-md text-[#637989] flex justify-start gap-2">Last Name <p className="text-red-500">*</p></label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <label className="font-semibold text-md text-[#637989] flex justify-start gap-2">Phone Number <p className="text-red-500">*</p></label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <label className="font-semibold text-md text-[#637989] flex justify-start gap-2">Email Address <p className="text-red-500">*</p></label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                        <label className="font-semibold text-md text-[#637989] flex justify-start gap-2">Property Address</label>
                        <input className="border border-gray-400 px-3 py-2 mb-5 text-sm w-full outline-none" placeholder="123 Main Street" />
                    </div>
                    <button className="w-full bg-[#0063a5] text-white py-2 rounded" onClick={() => handleLogin()}>Request Access</button>
                </div>
            </div>
            <LoginFooter />
        </div>
    )
}

export default CreatNewAccount;