import { FC } from "react";

const LoginFooter:FC = () => {
    return(
        <div className="w-full py-2 px-8 flex flex-col justify-center items-start bg-[#2b323b] absolute -bottom-12">
            <p className="text-white text-xs font-medium">Property Managers, LLC</p>
            <a href="" className="text-white text-xs font-medium">(786) 506-9416</a>
            <div className="flex justify-center items-center gap-2">
                <a className="text-white text-xs font-medium" href="/">Visit Our Website</a>
                <a className="text-white text-xs font-medium" href="">Help</a>
                <a className="text-white text-xs font-medium" href="">Privacy</a>
                <a className="text-white text-xs font-medium" href="">Terms</a>
            </div>
        </div>
    )
}

export default LoginFooter