import { faClose, faFilter, faLocation, faLocationDot, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type VacanciesHeaderProps = {
    isShowMap?: boolean
    setIsShowMap?: any
    isShowFilter?: boolean
    setIsShowFilter?: any
}

const VacanciesHeader: FC<VacanciesHeaderProps> = ({ isShowMap, setIsShowMap, isShowFilter, setIsShowFilter }) => {

    const handleShowMap = () => {
        setIsShowMap(!isShowMap);
    }

    const handleShowFilter = () => {
        setIsShowFilter(!isShowFilter)
    }

    return (
        <div className="w-full flex justify-between items-center px-4 py-1 bg-[#676767] lg:flex-row md:flex-row sm:flex-row flex-col gap-4">
            <div className="flex justify-center items-center gap-4">
                <span className="uppercase text-white">Sort By</span>
                <select name="" id="" className="outline-none py-1 text-[#303030]">
                    <option value="MostRecent">Most Recent</option>
                    <option value="RentRow">Rent(Low to High)</option>
                    <option value="RentHigh">Rent(High to Low)</option>
                    <option value="Bedrooms">Bedrooms</option>
                </select>
            </div>
            <div className="flex h-full justify-center items-center gap-4">
                <div className="h-full px-2 flex justify-center items-center gap-1 hover:bg-[#2d2f30] transition duration-300 cursor-pointer" onClick={() => handleShowMap()}>
                    <FontAwesomeIcon icon={isShowMap ? faClose : faLocationDot} className="text-white" />
                    <span className="uppercase text-white text-md font-medium">{isShowMap ? "Close Map" : "Show map"}</span>
                </div>
                <div className="h-full px-2 flex justify-center items-center gap-1 hover:bg-[#2d2f30] transition duration-300 cursor-pointer" onClick={() => handleShowFilter()}>
                    <FontAwesomeIcon icon={isShowFilter ? faClose : faFilter} className="text-white" />
                    <span className="uppercase text-white text-md font-medium">{isShowFilter ? "Close Filter" : "Filter"}</span>
                </div>
            </div>
        </div>
    )
}

export default VacanciesHeader;