import { FC } from "react";
import InputElement from "../components/element/InputElement";

const ContactUs:FC = () => {
    return(
        <section className="h-full relative transition-colors duration-500 mt-16">
            <div className="w-full mx-auto flex flex-col justify-center items-center">
                <div className="w-full h-60 flex items-center justify-center relative z-10">
                    <img className="w-full h-full object-cover absolute" src="/image/bg-image.jpg" alt="" />
                    <div className="w-full h-full bg-[rgba(0,0,0,0.3)] absolute"></div>
                    <span className="text-white lg:text-6xl md:text-5xl sm:text-4xl text-3xl z-10 font-medium uppercase leading-normal">Contact us</span>
                </div>
                <div className="w-full max-w-5xl py-16 mb-6 flex flex-col justify-start items-start">
                    <div className="w-full flex lg:flex-row md:flex-row sm:flex-col flex-col justify-start items-start">
                        <div className={`lg:w-[50%] md:w-[50%] sm:w-full px-4 w-full space-y-2`}>
                            <h1 className="uppercase text-2xl py-3 font-thin">Email Us:</h1>
                            <InputElement />
                        </div>
                        <div className="lg:w-[50%] md:w-[50%] sm:w-full w-full px-4">
                            <div className="w-full pl-6 mt-4">
                                <h1 className="uppercase text-2xl py-3 font-thin">Call us.</h1>
                                <p className="text-lg py-3 font-thin text-gray-400">(786) 506-9416</p>
                            </div>
                            <div className="w-full pl-6 mt-4">
                                <h1 className="uppercase text-2xl py-3 font-thin">Visit us.</h1>
                                <p className="text-lg font-thin" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>By Appointment Only</p>
                                <p className="text-lg font-thin" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>4798 WREN DRIVE</p>
                                <p className="text-lg font-thin" style={{ "fontFamily": "Droid Serif", "color": "#6b6767 " }}>SAINT CLOUD, FL 34772</p>
                            </div>
                            <div className="relative w-full h-[300px] overflow-hidden bg-cover bg-[50%] bg-no-repeat mt-12 lg:ml-6">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11672.945750644447!2d-122.42107853750231!3d37.7730507907087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858070cc2fbd55%3A0xa71491d736f62d5c!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1619524992238!5m2!1sen!2sus"
                                    width="100%" height="480" style={{ "border": 0 }} loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs;