import { FC } from "react";

const OwnerFooter: FC = () => {
    return (
        <div className="flex flex-col justify-start items-center mt-6">
            <h1 className="text-md font-bold text-gray-400">Property Managers, LLC</h1>
            <a href="" className="mt-4 text-gray-400">4798 WREN DRIVE</a>
            <a href="" className="text-gray-400 text-sm">SAINT CLOUD, FL 34772</a>
            <a href="" className="text-gray-400 text-sm">(786) 506-9416</a>
            <a href="" className="text-gray-400 text-sm">www.NadlanManagement.com</a>
            <img src="/image/Appfolio.svg" className="mt-6 w-32" alt="" />
        </div>
    )
}

export default OwnerFooter