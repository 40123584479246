import { FC } from "react";

const InputElement: FC = () => {
    return (
        <div className="w-full">
            <label htmlFor="requiredfield" className="w-full flex flex-row justify-start items-center gap-2" style={{ "fontStyle": "italic" }}><p className="text-red-500">*</p>Indicates required field</label>
            <div className="w-full flex flex-col justify-center items-start space-y-6">
                <div className="w-full mt-2 space-y-1">
                    <label htmlFor="name" className="flex justify-start items-center gap-1 text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>
                        Name<p className="text-red-500">*</p>
                    </label>
                    <div className="w-full flex justify-center items-center gap-4">
                        <input type="text" className="border border-gray-300 p-2 w-[45%] rounded" placeholder="First" />
                        <input type="text" className="border border-gray-300 p-2 w-[55%] rounded" placeholder="Last" />
                    </div>
                </div>
                <div className="w-full mt-2 space-y-1">
                    <label htmlFor="name" className="flex justify-start items-center gap-1 text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>
                        Email<p className="text-red-500">*</p>
                    </label>
                    <input type="text" className="border border-gray-300 p-2 w-full rounded" />
                </div>
                <div className="w-full mt-2 space-y-1">
                    <label htmlFor="name" className="flex justify-start items-center gap-1 text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>
                        Phone<p className="text-red-500">*</p>
                    </label>
                    <input type="text" className="border border-gray-300 p-2 w-full rounded" />
                </div>
                <div className="w-full mt-2 space-y-1">
                    <label htmlFor="name" className="flex justify-start items-center gap-1 text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>
                        Comment<p className="text-red-500">*</p>
                    </label>
                    <textarea className="border border-gray-300 p-2 w-full rounded" rows={8} />
                </div>
                <button className="py-2 px-6 bg-white border-gray-400 border-2 rounded-md text-gray-400 text-sm hover:bg-gray-400 uppercase hover:text-white transition duration-300">Submit</button>
            </div>
        </div>
    )
}

export default InputElement;