import Header from "./components/Header";
import HomePage from "./containers/Homepage";
import OurTechnology from "./containers/OurTechnology";
import Footer from "./components/Footer";
import MaintenanceRequest from "./containers/MaintenanceRequest";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Vacancies from "./containers/Vacancies";
import TenantPortal from "./containers/TenantPortal";
import UsefulLinks from "./containers/UsefulLinks";
import ManagementServices from "./containers/ManagementServices";
import ContactUs from "./containers/ContactUs";
import Tenant from "./containers/Tenant/Tenant";
import ForgotPassword from "./containers/Tenant/ForgotPassword";
import CheckEmail from "./containers/Tenant/CheckEmail";
import CreatNewAccount from "./containers/Tenant/CreatNewAccount";
import OwnerLogin from "./containers/Owner/Login";
import OwnerAccountCreate from "./containers/Owner/Creat";
import Forgotpassword from "./containers/Owner/ForgotPassword";

function App() {

  const location = useLocation();

  const noHeaderRoutes = ['/tenant', '/forgotpassword', "/checkemail", "/createnewaccount", "/owner", "/createnewpassword", "/forgotownerpassword"];
  const noFooterRoutes = ['/tenant', '/forgotpassword', "/checkemail", "/createnewaccount", "/owner", "/createnewpassword", "/forgotownerpassword"];

  return (
    <>
      {!noHeaderRoutes.includes(location.pathname) && <Header />}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/vacancies" element={<Vacancies />} />
        <Route path="/ourtechnology" element={<OurTechnology />} />
        <Route path="/maintenance" element={<MaintenanceRequest />} />
        <Route path="/tenantportal" element={<TenantPortal />} />
        <Route path="/usefullinks" element={<UsefulLinks />} />
        <Route path="/managementservices" element={<ManagementServices />} />
        <Route path="/contactus" element={<ContactUs />} />

        <Route path="/tenant" element={<Tenant />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/checkemail" element={<CheckEmail />} />

        <Route path="/owner" element={<OwnerLogin />} />
        <Route path="/createnewaccount" element={<CreatNewAccount />} />
        <Route path="/createnewpassword" element={<OwnerAccountCreate />} />
        <Route path="/forgotownerpassword" element={<Forgotpassword />} />


      </Routes>
      {!noFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  )
}

export default function Root() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}