import { FC } from "react";
import LoginFooter from "../../components/element/LoginFooter";

const CheckEmail: FC = () => {
    return (
        <div className="w-full h-screen flex flex-col justify-center items-center py-12 bg-[#f2f7fa] relative">
            <div className="lg:w-96 md:w-96 sm:w-full w-full flex flex-col justify-start items-center rounded-lg shadow-lg py-4 bg-white">
                <div className="w-full flex flex-col justify-center items-center mt-12">
                    <a href="/">
                        <img src="/image/loginLogo.jpg" alt="" />
                    </a>
                </div>
                <div className="w-full flex flex-col justify-start items-start px-4 mt-8">
                    <span className="text-2xl">Check Your Email</span>
                    <span className="text-md mt-4">An email with instructions will be sent to <strong>dove.engineer86@gmail.com</strong> if the account exists.</span>
                    <span className="text-md mt-4">Please be sure to check your spam and trash folders for emails from @appfolio.com.</span>
                    <span className="text-md mt-4">If you do not receive an email, reach out to Property Mangers LLC at (786) 506-9416.</span>
                </div>
            </div>
            <LoginFooter />
        </div>
    )
}

export default CheckEmail;