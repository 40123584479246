import { FC } from "react";
import InputElement from "../components/element/InputElement";

const MaintenanceRequest: FC = () => {
    return (
        <section className="h-full relative transition-colors duration-500 mt-16">
            <div className="w-full mx-auto flex flex-col justify-center items-center">
                <div className="w-full h-60 flex items-center justify-center relative z-10">
                    <img className="w-full h-full object-cover absolute" src="/image/bg-image.jpg" alt="" />
                    <div className="w-full h-full bg-[rgba(0,0,0,0.3)] absolute"></div>
                    <span className="text-white lg:text-6xl md:text-5xl sm:text-4xl text-3xl z-10 font-medium uppercase leading-normal">Maintenance Requests</span>
                </div>
                <div className="w-full max-w-5xl py-16 mb-6 flex lg:flex-row md:flex-row sm:flex-col flex-col justify-start items-start">
                    <div className="lg:w-[50%] md:w-[50%] sm:w-full w-full px-4">
                        <div className="w-full">
                            <div className="w-full">
                                <h1 className="uppercase text-2xl">Already have an account?</h1>
                                <h1 className="uppercase text-2xl py-2 font-thin">Submit maintenance requests.</h1>
                            </div>
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>In the case of an emergency only, call us immediately. Otherwise,
                                please make all inquiries through the tenant portal so that we can better respond to and track your maintenance requests.</span>
                        </div>
                        <div className="w-full mt-6">
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Here are your points of contact:</span>
                            <div className="w-full pl-6 space-y-1 mt-2">
                                <li style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>
                                    Through the <span className="font-bold text-gray-400">Tenant Portal</span> (best method)
                                </li>
                                <li style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>
                                    Call <span className="text-gray-400">(786) 506-9416</span>
                                </li>
                                <div className="py-6 lg:pl-10 md:pl-10 sm:pl-0 pl-0 w-full justify-center items-center">
                                    <button className="py-2 lg:px-6 px-2 bg-white border-gray-400 border-2 rounded-md text-gray-400 text-xs hover:bg-gray-400 uppercase hover:text-white transition duration-300">Login to make a maintenance request</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`lg:w-[50%] md:w-[50%] sm:w-full px-4 w-full`}>
                        <div className="w-full">
                            <h1 className="uppercase text-2xl">Don't have an account?</h1>
                            <h1 className="uppercase text-2xl py-2 font-thin">Have Question?</h1>
                        </div>
                        <div className="w-full">
                            <span className="text-lg" style={{ "fontFamily": "Droid Serif", "color": "rgb(68, 68, 68)" }}>Just let us know you want to make a maintenance request online by completing the form below and we’ll email you an account activation link.</span>
                        </div>
                        <InputElement />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MaintenanceRequest;