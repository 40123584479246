export const category = [
    {
        id: 0,
        category: "Home"
    },
    {
        id: 1,
        category: "Vacancies"
    },
    {
        id: 2,
        category: "Tenants"
    },
    {
        id: 3,
        category: "Owners"
    },
    {
        id: 4,
        category: "Contact Us"
    },
    {
        id: 5,
        category: "Login"
    }
]

export const childrenCategory = [
    {
        id: 0,
        title: "Tenant Portal",
        parentID: 2
    },
    {
        id: 1,
        title: "Maintenance Requests",
        parentID: 2
    },
    {
        id: 2,
        title: "Useful Links",
        parentID: 2
    },
    {
        id: 3,
        title: "Management Services",
        parentID: 3
    },
    {
        id: 4,
        title: "Our Technology",
        parentID: 3
    },
    {
        id: 5,
        title: "Tenant",
        parentID: 5
    },
    {
        id: 6,
        title: "Owner",
        parentID: 5
    }
]

export const Technology = [
    {
        title: "More effectively market your properties and fill vacancies sooner.",
        content: "Our software allows us to quickly advertise vacancies online, posting to our website, and hundreds of other listing sites. Applicants can also apply right from their smartphones."
    },
    {
        title: "Price rentals right for your market and reduce vacancies.",
        content: "Our built-in rental comparison tool provides actual rental rates for units similar to yours in the same geographic location. This insight allows us to maximize your revenue and fill vacancies faster."
    },
    {
        title: "Screen for the best residents.",
        content: "Streamlined, built in resident screening includes standard background and credit checks along with past rent payment history. Screens can be completed in minutes – enabling us to place the highest quality residents quickly."
    },
    {
        title: "Collect rent faster with online payment options.",
        content: "Modern residents expect easy, online payment options. Our software gives residents three convenient ways to pay electronically (Cash, E-check, or with a credit card)."
    },
    {
        title: "Handle property maintenance issues faster.",
        content: "We use our software for electronic work-orders and communication with vendors so we can quickly resolve issues."
    },
]

export const PortalServices = [
    {
        image: "/image/section-image1.jpg",
        title: "Pay Rent Online",
        content: "Log in to the Tenant Portal to pay by eCheck or credit/debit card."
    },
    {
        image: "/image/section-image2.jpg",
        title: "View Your Account",
        content: "Log in to your Tenant Portal to view your account history, see the status of your maintenance requests, and more."
    },
    {
        image: "/image/section-image3.jpg",
        title: "MAintenance Requests",
        content: "Log in to the Tenant Portal to enter repair and maintenance requests, or call us, 24 hours a day, 7 days a week."
    }
]

export const PriceRange = [
    { content: "$1,300" },
    { content: "$1,400" },
    { content: "$1,500" },
    { content: "$1,600" },
    { content: "$1,700" },
    { content: "$1,800" },
    { content: "$1,900" },
    { content: "$2,000" },
    { content: "$2,250" },
    { content: "$2,500" },
    { content: "$2,750" },
    { content: "$3,000" },
    { content: "$3,500" },
    { content: "$4,000" },
    { content: "$4,500" },
    { content: "$5,000" },
]